html,
body {
  height: 100%;
  margin: 0;
  font-family: 'Barlow', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f6f6f6;
  padding: 0;
}

#root,
#root > div:first-child,
.full-height {
  display: flex;
  flex-flow: column;
  height: 100%;
}

.grow {
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: auto;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 4px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #979fa8;
  border-radius: 4px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #6d737b;
}

.scroller {
  overflow-y: scroll;
  scrollbar-color: #0a4c95 #c2d2e4;
}
